import { Link, NavLink } from 'react-router-dom'
import logo_sm from '@/assets/logo-sm.webp'
import { useEffect, useState } from 'react'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'

type HeaderProps = {
    hireBadge: boolean
}

const navigation = [
    { name: 'Home', to: '/', current: true },
    { name: 'Firmen', to: '/subsidiaries', current: false },
    { name: 'Karriere', to: '/career', current: false },
    { name: 'Kontakt', to: '/contact', current: false }
]
function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}
export const Header = (_props: HeaderProps) => {
    const [scroll, setScroll] = useState(false)
    useEffect(() => {
        window.addEventListener('scroll', () => {
            setScroll(window.scrollY > 50)
        })
    }, [])

    return (
        <>
            <header
                className={
                    'w-full lg:px-16 px-4 bg-white flex flex-wrap items-center py-4 sticky top-0 z-[100]' +
                    (scroll ? ' shadow-md' : '')
                }>
                <Disclosure as='nav' className={'mt-auto w-full max-w-[85rem] md:px-4 mx-auto'}>
                    <div className='flex-1 flex grow w-full justify-between items-center'>
                        <div className='relative flex h-16 items-center justify-between w-full'>
                            <div className='absolute end-0 flex items-center sm:hidden'>
                                {/* Mobile menu button*/}
                                <DisclosureButton className='group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-black/10 focus:outline-none focus:bg-black/10'>
                                    <span className='absolute ' />
                                    <span className='sr-only'>Open main menu</span>
                                    <div aria-hidden='true' className='block h-6 w-6 group-data-[open]:hidden'>
                                        <svg
                                            className='fill-current text-gray-900'
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='24'
                                            height='24'
                                            viewBox='0 0 20 20'>
                                            <title>menu</title>
                                            <path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z'></path>
                                        </svg>
                                    </div>
                                    <div aria-hidden='true' className='hidden h-6 w-6 group-data-[open]:block'>
                                        <svg
                                            className='fill-current text-gray-900'
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='24'
                                            height='24'
                                            viewBox='-250 100 500 500'>
                                            <g transform='rotate(45)'>
                                                <rect x='212' y='0' width='76' height='500' />
                                                <rect y='212' x='0' height='76' width='500' />
                                            </g>
                                        </svg>
                                    </div>
                                </DisclosureButton>
                            </div>
                            <div className='flex flex-1 items-center justify-center sm:items-stretch sm:justify-start'>
                                <div className='flex-1 flex justify-between items-center'>
                                    <div className=''>
                                        <>
                                            <Link to='/' className='text-xl'>
                                                <img src={logo_sm} className='h-[58px]' alt='Majo Holding GmbH' />
                                            </Link>
                                        </>
                                    </div>
                                </div>
                                <div className='hidden sm:ml-6 sm:block'>
                                    <ul className='sm:flex items-center justify-between text-base text-gray-700 pt-4 sm:pt-0'>
                                        {navigation.map(item => (
                                            <li
                                                key={item.name}
                                                className='rounded-lg border border-transparent hover:bg-black/10 focus:outline-none focus:bg-black/10'>
                                                <NavLink to={item.to} className={'sm:p-4 py-3 px-0 block'}>
                                                    {item.name}
                                                </NavLink>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className='absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0'></div>
                        </div>
                    </div>

                    <DisclosurePanel className='sm:hidden'>
                        <div className='space-y-1 px-2 pb-3 pt-2'>
                            {navigation.map(item => (
                                <NavLink
                                    key={item.name}
                                    to={item.to}
                                    aria-current={item.current ? 'page' : undefined}
                                    className={classNames(
                                        'text-base text-gray-700 py-2 md:pt-0 hover:bg-black/10 focus:outline-none focus:bg-black/10',

                                        'block rounded-md px-3  text-base font-medium'
                                    )}>
                                    {item.name}
                                </NavLink>
                            ))}
                        </div>
                        {scroll ? <></> : <hr />}
                    </DisclosurePanel>
                </Disclosure>
            </header>
        </>
    )
}
