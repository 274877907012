import img_1 from '@/assets/h2.webp'
import indeed_logo from '@/assets/indeed.png'

export const CareerPlaceholder = () => {
    return (
        <>
            <main id='content' className='py-10 flex-grow flex items-center'>
                <div className='max-w-[85rem]  mx-auto px-4 sm:px-6 lg:px-8'>
                    <div className='grid md:grid-cols-2 gap-4 md:gap-8 xl:gap-20 md:items-center'>
                        <div className=' items-center'>
                            <h1 className='block text-3xl font-bold text-gray-800 sm:text-4xl lg:text-6xl lg:leading-tight'>
                                Wir wachsen und suchen Verstärkung -{' '}
                                <span className='text-[#826E42]'>bereit für Neues?</span>
                            </h1>
                            <p className='mt-3 text-lg text-gray-800'>
                                Innerhalb der Unternehmensgruppe suchen wir aktiv nach Verstärkung, zum Beispiel aus den
                                Bereichen:
                            </p>
                            <ul className='list-disc ml-5'>
                                <li>Buchhaltung</li>
                                <li>Liegenschafts- und WEG Betreuung</li>
                            </ul>
                            <div className='flex row mt-4'>
                                <div>
                                    <p className='mt-3 text-lg text-gray-800 mr-4'>
                                        Details zu aktuell offenen Stellen innerhalb der Holding bzw. der
                                        Unternehmensgruppe finden Sie z.B. bei{' '}
                                        <a
                                            href='https://de.indeed.com/jobs?q=majo+holding'
                                            target='_blank'
                                            className='underline'>
                                            Indeed
                                        </a>
                                        .
                                    </p>
                                </div>
                                <div>
                                    <a href='https://de.indeed.com/jobs?q=majo+holding' target='_blank'>
                                        <img
                                            className='w-full rounded-md'
                                            src={indeed_logo}
                                            alt='Managing the future.'
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className='relative ms-4'>
                            <img className='w-full rounded-md' src={img_1} alt='Managing the future.' />
                            <div className='absolute inset-0 -z-[1] bg-gradient-to-tr from-[#826E42] via-white/0 to-white/0 size-full rounded-md mt-4 -mb-4 me-4 -ms-4 lg:mt-6 lg:-mb-6 lg:me-6 lg:-ms-6'></div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export function Component() {
    return <CareerPlaceholder />
}
