import { RouteObject } from 'react-router-dom'
import { Layout } from '@/app/Layout.tsx'
import { Home } from '@/app/pages/Home.tsx'
import { Imprint } from '@/app/pages/Imprint.tsx'
import { DataPrivacy } from '@/app/pages/DataPrivacy.tsx'
import { Subsidiaries } from '@/app/pages/Subsidiaries.tsx'
import { Error404 } from '@/app/errors/Error404.tsx'
import { Contact } from '@/app/pages/Contact.tsx'
import { CareerPlaceholder } from '@/app/pages/CareerPlaceholder.tsx'
import { Career2 } from '@/app/pages/Career2.tsx'

export const routes: RouteObject[] = [
    {
        element: <Layout />,
        children: [
            {
                index: true,
                //lazy: () => import('@/app/pages/Home.tsx'),
                path: '/',
                element: <Home />
            },
            {
                //lazy: () => import('@/app/pages/Home.tsx'),
                path: '/imprint',
                element: <Imprint />
            },
            {
                //lazy: () => import('@/app/pages/Home.tsx'),
                path: '/data-privacy',
                element: <DataPrivacy />
            },
            {
                //lazy: () => import('@/app/pages/Home.tsx'),
                path: '/career',
                element: <Career2 />
            },
            {
                //lazy: () => import('@/app/pages/Home.tsx'),
                path: '/career-ph',
                element: <CareerPlaceholder />
            },
            {
                //lazy: () => import('@/app/pages/Home.tsx'),
                path: '/subsidiaries',
                element: <Subsidiaries />
            },
            {
                //lazy: () => import('@/app/pages/Home.tsx'),
                path: '/contact',
                element: <Contact />
            },
            {
                element: <Error404 />,
                //lazy: () => import('@app/views/errors/Error404'),
                path: '*'
            }
        ]
    }
]
