import { Link } from 'react-router-dom'
//import logo_ph from '@/assets/logos/logo-ph.webp'
import logo_ce from '@/assets/logos/logo-ce.webp'
import logo_ps from '@/assets/logos/logo-ps.webp'
import logo_be from '@/assets/logos/logo-be.webp'
import logo_hvs from '@/assets/logos/logo-hvs.webp'
import logo_hvf from '@/assets/logos/logo-hvf.webp'
import logo_his from '@/assets/logos/logo-his.webp'
import logo_kst from '@/assets/logos/logo-kst.webp'
import logo_pi from '@/assets/logos/logo-pi.webp'
import logo_uvg from '@/assets/logos/logo-uvg.webp'
import logo_ivs from '@/assets/logos/logo-ivs.webp'
import logo_3k1 from '@/assets/logos/logo-3komma1.webp'

export const Subsidiaries = () => {
    const showLink = true
    const subs = [
        {
            id: 1,
            name: 'Behringer Hausverwaltung GmbH',
            name2: '',
            adr1: 'Gewerbering 11',
            adr2: '86922 Eresing',
            cat: 'Hausverwaltung',
            link: 'https://www.behringer-hausverwaltung.de',
            mail: 'info@behringer-hausverwaltung.de',
            phone: '+49 (0) 89 7442 4430',
            img: logo_be
        },
        {
            id: 2,
            name: 'Hausverwaltung Schmidt GmbH',
            name2: '',
            adr1: 'Gewerbering 11',
            adr2: '86922 Eresing',
            cat: 'Hausverwaltung',
            link: 'https://www.hausverwaltungschmidt.de',
            mail: 'info@hausverwaltungschmidt.de',
            phone: '+49 (0) 8193 99889-0',
            img: logo_hvs
        },
        {
            id: 3,
            name: 'Cool Expert GmbH',
            name2: '',
            adr1: 'Besenacker 16',
            adr2: '35108 Allendorf',
            cat: 'Kälte- Klimatechnik',
            link: 'https://cool-expert.de',
            mail: 'info@cool-expert.de',
            phone: '+49 (0) 6452 9290-0',
            img: logo_ce
        },
        {
            id: 4,
            name: 'Hausverwaltung-Immobilien Flack GmbH',
            name2: '',
            adr1: 'Allacher Straße 4',
            adr2: '85757 Karlsfeld',
            cat: 'Hausverwaltung',
            link: 'https://www.hvi-flack.de',
            mail: 'info@hvi-flack.de',
            phone: '+49 (0) 8131 27580 - 0',
            img: logo_hvf
        },
        {
            id: 5,
            name: 'HIS Hausverwaltung Mittelrhein GmbH',
            name2: '',
            adr1: 'Johannesstraße 42',
            adr2: '56112 Lahnstein',
            cat: 'Hausverwaltung',
            link: 'https://www.hausverwaltung-koblenz.de',
            mail: 'info@hv-koblenz.de',
            phone: '+49 (0) 2621 62919-0',
            img: logo_his
        },
        {
            id: 6,
            name: 'Kusterer GmbH & Co Bedachungs KG',
            name2: '',
            adr1: 'Max-Eyth-Straße 6',
            adr2: '35415 Pohlheim',
            cat: 'Dachdecker Meisterbetrieb',
            link: 'https://www.kusterer-bedachungen.de',
            mail: 'info@kusterer-bedachungen.de',
            phone: '+49 (0) 6403 61826',
            img: logo_kst
        },
        {
            id: 7,
            name: 'PRINTZ & SCHWENK',
            name2: 'M.G. Hauck GmbH & Co. KG',
            adr1: 'Neue Mainzer Straße 22',
            adr2: '60311 Frankfurt/Main',
            cat: 'Hausverwaltung',
            link: 'https://www.printz-schwenk.de',
            mail: 'info@printz-schwenk.de',
            phone: '+49 (0) 69 959646 - 0',
            img: logo_ps
        },
        {
            id: 8,
            name: 'Pahnke Ingenieurgesellschaft mbH',
            name2: '',
            adr1: 'Judengasse 11',
            adr2: '61169 Friedberg (Hessen)',
            cat: 'Ingenieurbüro, TGA Planung',
            link: 'https://www.pahnke-ingenieurgesellschaft.de',
            mail: 'info@pahnke-partner.de',
            phone: '+49 (0) 6031 16157',
            img: logo_pi
        },
        {
            id: 9,
            name: 'Union Verwaltungs-Gesellschaft mbH',
            name2: '',
            adr1: 'Graben 19',
            adr2: '97421 Schweinfurt',
            cat: 'Hausverwaltung',
            link: 'https://www.union-verwaltung.de',
            mail: 'info@union-verwaltung.de',
            phone: '+49 (0) 9721 7298 - 0',
            img: logo_uvg
        },
        {
            id: 10,
            name: 'Immobilien Verwaltungs-Kontor GmbH',
            name2: '',
            adr1: 'Nikolaus-Otto-Straße 9',
            adr2: '55129 Mainz',
            cat: 'Hausverwaltung',
            link: 'https://www.ivkontor.de',
            mail: 'info@ivkontor.de',
            phone: '+49 (0) 6131  21336 0',
            img: logo_ivs
        },
        {
            id: 11,
            name: '3KOMMA1 Immobilienservices ',
            name2: 'GmbH & Co. KG',
            adr1: 'Europaring 60',
            adr2: '40878 Ratingen',
            cat: 'Hausverwaltung',
            link: 'https://www.3komma1-services.de',
            mail: 'info@3KOMMA1.de',
            phone: '+49 (0) 2102 9194 31',
            img: logo_3k1
        }
    ]
    const listItems = subs.map(sub => (
        <div key={sub.id}>
            <div className='w-[350px] bg-gray-100 h-full  shadow-md border rounded-xl duration-500 hover:scale-105 hover:shadow-xl'>
                <a target={'_blank'} href={sub.link}>
                    <img src={sub.img} alt={sub.name} className='h-[175px] w-[350px] object-cover rounded-t-xl' />
                </a>
                <div className='px-2 py-4 w-[350px] '>
                    <span className='text-gray-400 mr-3 uppercase text-xs'>{sub.cat}</span>
                    <p className='text-lg font-normal text-black truncate block'>{sub.name}</p>
                    <p className='text-lg font-normal text-black truncate block'>
                        {sub.name2 ? sub.name2 : <>&nbsp;</>}
                    </p>
                    <div className='flex row mt-2'>
                        <div>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='16'
                                height='16'
                                fill='currentColor'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                className='h-4 w-4 mt-1'
                                viewBox='-83.5 0 500 500'>
                                <g fillRule='evenodd'>
                                    <path d='M166.5,482 C 245,396 328,273 333,181 A166.5,166.5 0 0 0 0,181 C 5,273 88,396 166.5,482 Z M 226,178 A59.5,59.5 0 0 1 107,178 A59.5,59.5 0 0 1 226,178 Z' />
                                </g>
                            </svg>
                        </div>
                        <div className='ml-2'>
                            <p className='text-md font-medium text-black cursor-auto'>{sub.adr1}</p>
                            <p className='text-md font-medium text-black cursor-auto '>{sub.adr2}</p>
                        </div>
                    </div>
                    <div className='flex row mt-2 items-center'>
                        <div>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='16'
                                height='16'
                                fill='currentColor'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                className='h-4 w-4'
                                viewBox='30 31.5 452 452'>
                                <g fillRule='evenodd'>
                                    <path d='M225,62a38,38 0 01 50,0l73,61v-59.6a10,10 0 01 10-10h59a10,10 0 01 10,10v125.6l68,56.5a10,10 0 01 0,13l-19.8,23.5a10,10 0 01-13,0l-212.2-177l-212.2,177a10,10 0 01-13,0l-19.8-23.5a10,10 0 01 0-13z' />
                                    <path d='M250,132l177,146v149a20,20 0 01-20,20h-118v-118h-78v118h-118a20,20 0 01-20-20v-149z' />
                                </g>
                            </svg>
                        </div>
                        <div className='ml-2'>
                            <p className='text-gray-800'>
                                <a className={'hover:underline'} target={'_blank'} href={sub.link}>
                                    {sub.link}
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className='flex row  items-center'>
                        <div>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='16'
                                height='16'
                                fill='currentColor'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                className='h-4 w-4'
                                viewBox='30 31.5 452 452'>
                                <g fillRule='evenodd'>
                                    <path d='M0,50a40,49 0 01 40-49h420a40,49 0 01 40,49c0,22-28,55-46,70l-171,118.5c-27,15.5-39,15.5-66,0l-171-118.5c-28-15-46-48-46-70z' />

                                    <path d='M0,127.5v221.5a45,45 0 00 45,45h415a45,45 0 00 40-45v-221.5c-8,8.5-16,15.5-26,22.5l-162,113c-47,31-77,31-124,0l-162-113c-10-6-18-14-26-22.5z' />
                                </g>
                            </svg>
                        </div>
                        <div className='ml-2'>
                            <p className='text-gray-800'>
                                <a className={'hover:underline'} target={'_blank'} href={'mailto:' + sub.mail}>
                                    {sub.mail}
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className='flex row  items-center'>
                        <div>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='16'
                                height='16'
                                fill='currentColor'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                className='h-4 w-4'
                                viewBox='30 31.5 452 452'>
                                <g fillRule='evenodd'>
                                    <path d='M128,21q40,75,53,91.5q10,15.5-2,27.5c-10,13-38,32-45,40c-10,9-12,15-3,32q54,105,158,156c18,10,20,5,32-5c8-8,20-27,35-41c10-11,20-12,32-3q14,11,88,51c9,5,11,8,9,27q-7,40-22,51q-67,53-133,29c-180-55-286-195-315-336q-4-62,36-105q10-13,30-17c35-11,42-6,47,2z' />
                                </g>
                            </svg>
                        </div>
                        <div className='ml-2'>
                            <p className='text-gray-800'>{sub.phone}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ))
    return (
        <>
            <main id='content' className='py-10 flex-grow flex flex-col items-center'>
                <div className='max-w-[85rem]  mx-auto px-4 sm:px-6 lg:px-8'>
                    <div className='text-center'>
                        <h1 className=' font-bold tracking-tight text-gray-900 text-4xl sm:text-5xl lg:text-6xl'>
                            Unternehmen der <span className='text-[#826E42]'>Majo Holding GmbH</span>.
                        </h1>
                    </div>
                    <section
                        id='subsidiaries'
                        className='w-fit  mx-auto grid auto-rows-fr grid-cols-1 xl:grid-cols-3 md:grid-cols-2 gap-y-4 gap-x-4 mt-20 mb-2'>
                        {listItems}
                    </section>
                    <div className='max-w-[85rem]  mx-auto px-4 sm:px-6 lg:px-8'>
                        <div className='relative isolate px-6 pt-14 lg:px-8'>
                            <div className='mx-auto max-w-2xl'>
                                {showLink ? (
                                    <div className='flex sm:mb-8 sm:flex sm:justify-center'>
                                        <div className='relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20'>
                                            <Link to='/' className='font-semibold text-[#826E42]'>
                                                <span className='absolute inset-0' aria-hidden='true'></span>Zurück zur
                                                Startseite <span aria-hidden='true'>&rarr;</span>
                                            </Link>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export function Component() {
    return <Subsidiaries />
}
