import { Link } from 'react-router-dom'

export const DataPrivacy = () => {
    return (
        <>
            <main id='content' className='py-10 flex-grow flex items-center text-justify'>
                <div className='max-w-[85rem]  mx-auto px-4 sm:px-6 lg:px-8'>
                    <div>
                        <h1 className='text-4xl sm:text-5xl lg:text-6xl text-gray-800 font-bold mb-2'>
                            Datenschutzerklärung
                        </h1>
                        <p className='text-gray-800 font-normal mb-1 mt-2'>
                            Wir freuen uns über Ihren Besuch auf unserer Internetseite majo-holding.de und Ihr Interesse
                            an unserem Unternehmen.
                        </p>
                        <p className='text-gray-800 font-normal mb-1 mt-2'>
                            Der Schutz Ihrer personenbezogenen Daten, wie z.B. Geburtsdatum, Name, Telefonnummer,
                            Anschrift etc., ist uns ein wichtiges Anliegen.
                        </p>
                        <p className='text-gray-800 font-normal mb-1 mt-2'>
                            Der Zweck dieser Datenschutzerklärung besteht darin, Sie über die Verarbeitung Ihrer
                            personenbezogenen Daten zu informieren, die wir bei einem Seitenbesuch von Ihnen sammeln.
                            Unsere Datenschutzpraxis steht im Einklang mit den gesetzlichen Regelungen der
                            Datenschutzgrundverordnung der EU (DSGVO) und dem Bundesdatenschutzgesetz (BDSG). Die
                            nachfolgende Datenschutzerklärung dient der Erfüllung der sich aus der DSGVO ergebenden
                            Informationspflichten. Diese finden sich z.B. in Art. 13 und Art. 14 ff. DSGVO.
                        </p>
                        <h1 className='text-2xl text-gray-800 font-medium mb-1 mt-4'>Verantwortlich</h1>
                        <p className='text-gray-800 font-normal mb-1 mt-2'>
                            Die Datenverarbeitung auf dieser Webseite erfolgt durch die Majo Holding GmbH. Die
                            Kontaktdaten können Sie dem{' '}
                            <Link to='/imprint' className='underline'>
                                Impressum
                            </Link>{' '}
                            dieser Webseite entnehmen.
                        </p>
                        <h1 className='text-2xl text-gray-800 font-medium mb-1 mt-4'>
                            Kontaktdaten des Datenschutzbeauftragten
                        </h1>
                        <p className='text-gray-800 font-normal mb-1 mt-2'>
                            Wir haben einen externen Datenschutzbeauftragten gem. Art. 37 DSGVO bestellt. Unseren
                            Datenschutzbeauftragten erreichen Sie unter den nachfolgenden Kontaktdaten:
                        </p>
                        <div className='text-gray-800 font-normal'>Frank Wiemer</div>
                        <div className='text-gray-800 font-normal'>Fraunhoferstraße 9</div>
                        <div className='text-gray-800 font-normal'>ituso GmbH</div>
                        <div className='text-gray-800 font-normal'>85221 Dachau</div>
                        <div className='text-gray-800 font-normal'>Deutschland</div>
                        <div className='text-gray-800 font-normal'>E-Mail: Frank.Wiemer@ituso.de</div>

                        <h1 className='text-2xl text-gray-800 font-medium mb-1 mt-4'>
                            Hosting der Webseite, Erstellung von Logfiles
                        </h1>
                        <h2 className='text-lg text-gray-800 font-medium mb-1 mt-2'>
                            Wir hosten die Inhalte unserer Website bei Hetzner Online GmbH
                        </h2>
                        <p className='text-gray-800 font-normal mb-1 mt-2'>
                            Anbieter ist die Hetzner Online GmbH, Industriestr. 25, 91710 Gunzenhausen (nachfolgend
                            Hetzner). Details entnehmen Sie der Datenschutzerklärung von Hetzner:
                            <a
                                className={'hover:underline'}
                                target={'_blank'}
                                href='https://www.hetzner.com/de/legal/privacy-policy/'>
                                https://www.hetzner.com/de/legal/privacy-policy/
                            </a>
                            . Die Verwendung von Hetzner erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO.
                        </p>
                        <p className='text-gray-800 font-normal mb-1 mt-2'>
                            Wir haben einen Vertrag über Auftragsverarbeitung (AVV) zur Nutzung des oben genannten
                            Dienstes geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen
                            Vertrag, der gewährleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher
                            nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
                        </p>

                        <h2 className='text-lg text-gray-800 font-medium mb-1 mt-2'>
                            Welche personenbezogenen Daten werden erhoben und in welchem Umfang werden diese
                            verarbeitet?
                        </h2>
                        <p className='text-gray-800 font-normal mb-1 mt-2'>
                            Bei jedem Aufruf unserer Internetseite erfasst unser System automatisiert Daten und
                            Informationen des jeweils abrufenden Gerätes (z.B. Computer, Mobiltelefon, Tablet, etc.).
                        </p>
                        <ol className='list-decimal ml-4'>
                            <li>Informationen über den Browsertyp und die verwendete Version</li>
                            <li>Das Betriebssystem des Abrufgerätes</li>
                            <li>Hostname des zugreifenden Rechners</li>
                            <li>Die IP-Adresse des Abrufgerätes</li>
                            <li>Datum und Uhrzeit des Zugriffs</li>
                            <li>
                                Websites und Ressourcen (Bilder, Dateien, weitere Seiteninhalte), die auf unserer
                                Internetseite aufgerufen wurden
                            </li>
                            <li>
                                Websites, von denen das System des Nutzers auf unsere Internetseite gelangte
                                (Referrer-Tracking)
                            </li>
                            <li>Meldung, ob der Abruf erfolgreich war</li>
                            <li>Übertragene Datenmenge</li>
                        </ol>
                        <p className='text-gray-800 font-normal mb-1 mt-2'>
                            Diese Daten werden in den Logfiles unseres Systems gespeichert. Eine Speicherung dieser
                            Daten zusammen mit personenbezogenen Daten eines konkreten Nutzers findet nicht statt, so
                            dass eine Identifizierung einzelner Seitenbesucher nicht erfolgt.
                        </p>
                        <h2 className='text-lg text-gray-800 font-medium mb-1 mt-2'>
                            Rechtsgrundlage für die Verarbeitung personenbezogener Daten
                        </h2>
                        <p className='text-gray-800 font-normal mb-1 mt-2'>
                            Art. 6 Abs. 1 lit. f DSGVO (berechtigtes Interesse). Unser berechtigtes Interesse besteht
                            darin, die Erreichung des nachfolgend geschilderten Zwecks zu gewährleisten.
                        </p>

                        <h2 className='text-lg text-gray-800 font-medium mb-1 mt-2'>Zweck der Datenverarbeitung</h2>
                        <p className='text-gray-800 font-normal mb-1 mt-2'>
                            Die vorübergehende (automatisierte) Speicherung der Daten ist für den Ablauf eines
                            Websitebesuchs erforderlich, um eine Auslieferung der Website zu ermöglichen. Die
                            Speicherung und Verarbeitung der personenbezogenen Daten erfolgt zudem zur Erhaltung der
                            Kompatibilität unserer Internetseite für möglichst alle Besucher und zur
                            Missbrauchsbekämpfung und Störungsbeseitigung. Hierfür ist es notwendig, die technischen
                            Daten des abrufenden Rechners zu loggen, um so frühestmöglich auf Darstellungsfehler,
                            Angriffe auf unsere IT-Systeme und/oder Fehler der Funktionalität unserer Internetseite
                            reagieren zu können. Zudem dienen uns die Daten zur Optimierung der Website und zur
                            generellen Sicherstellung der Sicherheit unserer informationstechnischen Systeme.
                        </p>
                        <h2 className='text-lg text-gray-800 font-medium mb-1 mt-2'>Dauer der Speicherung</h2>
                        <p className='text-gray-800 font-normal mb-1 mt-2'>
                            Die Löschung der vorgenannten technischen Daten erfolgt, sobald sie nicht mehr benötigt
                            werden, um die Kompatibilität der Internetseite für alle Besucher zu gewährleisten,
                            spätestens aber 3 Monate nach Abruf unserer Internetseite.
                        </p>
                        <h2 className='text-lg text-gray-800 font-medium mb-1 mt-2'>
                            Widerspruchs- und Löschungsmöglichkeit
                        </h2>
                        <p className='text-gray-800 font-normal mb-1 mt-2'>
                            Sie können der Verarbeitung jederzeit gem. Art. 21 DSGVO widersprechen und eine Löschung von
                            Daten gem. Art. 17 DSGVO verlangen. Welche Rechte Ihnen zustehen und wie Sie diese geltend
                            machen, finden Sie im unteren Bereich dieser Datenschutzerklärung.
                        </p>
                        <h1 className='text-2xl text-gray-800 font-medium mb-1 mt-4'>Funktionen der Webseite</h1>
                        <p className='text-gray-800 font-normal mb-1 mt-2'>
                            Unsere Webseite bietet Ihnen verschiedene Funktionen, bei deren Nutzung von uns
                            personenbezogene Daten erhoben, verarbeitet und gespeichert werden. Nachfolgend erklären
                            wir, was mit diesen Daten geschieht:
                        </p>
                        <h2 className='text-xl text-gray-800 font-medium mb-1 mt-2'>Kontaktformular</h2>
                        <h2 className='text-lg text-gray-800 font-medium mb-1 mt-2'>
                            Welche personenbezogenen Daten werden erhoben und in welchem Umfang werden diese
                            verarbeitet?
                        </h2>
                        <p className='text-gray-800 font-normal mb-1 mt-2'>
                            Die von Ihnen in unsere Kontaktformulare eingegebenen Daten, die Sie in die Eingabemaske des
                            Kontaktformulares eingetragen haben.
                        </p>
                        <h2 className='text-lg text-gray-800 font-medium mb-1 mt-2'>
                            Rechtsgrundlage für die Verarbeitung personenbezogener Daten
                        </h2>
                        <p className='text-gray-800 font-normal mb-1 mt-2'>
                            Art. 6 Abs. 1 lit. a DSGVO (Einwilligung durch eindeutige bestätigende Handlung bzw.
                            Verhaltensweise)
                        </p>
                        <h2 className='text-lg text-gray-800 font-medium mb-1 mt-2'>Zweck der Datenverarbeitung</h2>
                        <p className='text-gray-800 font-normal mb-1 mt-2'>
                            Die über unser Kontaktformular bzw. über unsere Kontaktformulare aufgenommenen Daten werden
                            wir nur für die Bearbeitung der konkreten Kontaktanfrage verwenden, die durch das
                            Kontaktformular eingeht. Bitte beachten Sie, dass wir zur Erfüllung ihrer Kontaktanfrage
                            Ihnen unter Umständen auch E-Mails an die angegebene Adresse senden können. Dies hat den
                            Zweck, dass Sie von uns eine Bestätigung erhalten können, dass Ihre Anfrage an uns korrekt
                            weitergeleitet wurde. Der Versand dieser Bestätigungs-E-Mail ist jedoch für uns nicht
                            verpflichtend und dient nur Ihrer Information.
                        </p>
                        <h2 className='text-lg text-gray-800 font-medium mb-1 mt-2'>Dauer der Speicherung</h2>
                        <p className='text-gray-800 font-normal mb-1 mt-2'>
                            Nach Bearbeitung Ihrer Anfrage werden die erhobenen Daten unverzüglich gelöscht, soweit
                            keine gesetzlichen Aufbewahrungsfristen bestehen.
                        </p>
                        <h2 className='text-lg text-gray-800 font-medium mb-1 mt-2'>
                            Widerrufs- und Löschungsmöglichkeit
                        </h2>
                        <p className='text-gray-800 font-normal mb-1 mt-2'>
                            Die Widerrufs- und Löschungsmöglichkeiten richten sich nach den nachfolgend in dieser
                            Datenschutzerklärung geschilderten generellen Regelungen zum datenschutzrechtlichen
                            Widerrufsrecht und Löschungsrecht.
                        </p>
                        <h2 className='text-lg text-gray-800 font-medium mb-1 mt-2'>
                            Erforderlichkeit der Angabe personenbezogener Daten
                        </h2>
                        <p className='text-gray-800 font-normal mb-1 mt-2'>
                            Die Nutzung der Kontaktformulare erfolgt auf freiwilliger Basis und ist weder vertraglich
                            noch gesetzlich vorgeschrieben. Sie sind nicht verpflichtet mit uns über das Kontaktformular
                            Kontakt aufzunehmen, sondern können auch die weiteren, auf unserer Seite angegebenen
                            Kontaktmöglichkeiten, nutzen. Sofern Sie unser Kontaktformular nutzen möchten, müssen Sie
                            die als Pflichtangaben gekennzeichneten Felder ausfüllen. Sofern Sie die notwendigen Angaben
                            des Kontaktformulares nicht ausfüllen, können Sie entweder die Anfrage nicht absenden oder
                            wir können Ihre Anfrage leider nicht bearbeiten.
                        </p>
                        <h1 className='text-2xl text-gray-800 font-medium mb-1 mt-4'>
                            Statistische Auswertung, Webtracker
                        </h1>
                        <p className='text-gray-800 font-normal mb-1 mt-2'>
                            Auf unserer Webseite verwenden wir aktuell keine Webtracker.
                        </p>
                        <h1 className='text-2xl text-gray-800 font-normal mb-1 mt-4'>Einbindung externer Webdienste</h1>
                        <p className='text-gray-800 font-normal mb-1 mt-2'>
                            Auf unserer Webseite verwenden wir aktuell keine aktiven Inhalte (Webdienste) von externen
                            Anbietern.
                        </p>
                        <h1 className='text-2xl text-gray-800 font-normal mb-1 mt-4'>Cookies</h1>
                        <p className='text-gray-800 font-normal mb-1 mt-2'>
                            Wir verwenden innerhalb der Webseite temporäre und permanente Cookies. Als Cookies werden
                            kleine Dateien bezeichnet, die auf Ihrem Zugriffsgerät gespeichert werden. Innerhalb der
                            Cookies können unterschiedliche Angaben hinterlegt werden. Ein Cookie dient primär dazu,
                            Ihre Benutzerangaben oder auf das Gerät bezogene Informationen während oder auch nach Ihrem
                            Besuch des Onlineangebots zu speichern. Als temporäre Cookies, bzw. Session-Cookies oder
                            transiente Cookies, werden Cookies bezeichnet, die gelöscht werden, nachdem Sie das
                            Onlineangebot verlassen bzw. wenn Sie Ihren Browser schliessen. In einem solchen Cookie kann
                            z.B. der Login-Status gespeichert werden. Als permanent oder persistent werden Cookies
                            bezeichnet, die auch nach dem Schließen des Browsers gespeichert bleiben. So kann z.B. der
                            Login-Status wiederhergestellt werden, wenn Sie die Webseite nach mehreren Tagen erneut
                            aufsuchen. Ebenso können in einem solchen Cookie Daten gespeichert werden, die für
                            Reichweitenmessung oder Marketingzwecke verwendet werden können. Cookies, die durch uns
                            gesetzt werden, werden als First-Party Cookies bezeichnet, als Third-Party-Cookie werden
                            Cookies bezeichnet, die von Drittanbietern angeboten werden.
                        </p>
                        <p className='text-gray-800 font-normal mb-1 mt-2'>
                            Sie können die Verwendung von Cookies durch die Webseite jederzeit mittels einer
                            entsprechenden Einstellung in Ihrem Browser verhindern und damit der Setzung von Cookies
                            dauerhaft widersprechen. Ferner können bereits gesetzte Cookies jederzeit über Ihren Browser
                            oder andere Softwareprogramme gelöscht werden. Deaktivieren Sie die Setzung von Cookies im
                            genutzten Browser, sind eventuell nicht alle Funktionen der Webseite vollumfänglich nutzbar.
                        </p>
                        <p className='text-gray-800 font-normal mb-1 mt-2'>
                            Auf unserer Webseite verwenden wir aktuell ausschliesslich technisch notwendige Cookies.
                        </p>
                        <h1 className='text-2xl text-gray-800 font-normal mb-1 mt-4'>
                            Ihre Datenschutzrechte: Auskunft über die personenbezogene Daten, Berichtigung, Löschung,
                            Einschränkung, Widerspruch
                        </h1>
                        <p className='text-gray-800 font-normal mb-1 mt-2'>
                            Sie haben, bzw. jede betroffene Person hat das Recht auf Auskunft nach Art. 15 DSGVO, das
                            Recht auf Berichtigung und Verfollständigung nach Art. 16 DSGVO, das Recht auf Löschung nach
                            Art. 17 DSGVO, das Recht auf Einschränkung der Verarbeitung nach Art. 18 DSGVO, das Recht
                            auf Widerspruch nach Art. 21 DSGVO sowie das Recht auf Datenübertragbarkeit nach Art. 20
                            DSGVO. Beim Auskunftsrecht und beim Löschungsrecht gelten die Einschränkungen nach §§ 34 und
                            35 BDSG. Darüber hinaus besteht gem. Art. 77 DSGVO ein Beschwerderecht bei der zuständigen
                            Datenschutzaufsichtsbehörde.
                        </p>
                        <p className='text-gray-800 font-normal mb-1 mt-2'>
                            Sie haben das Recht, erteilte Einwilligungen gem. Art. 7 Abs. 3 DSGVO mit Wirkung für die
                            Zukunft zu widerrufen. Sie können der künftigen Verarbeitung der Sie betreffenden Daten nach
                            Maßgabe des Art. 21 DSGVO jederzeit widersprechen.
                        </p>
                        <p className='text-gray-800 font-normal mb-1 mt-2'>
                            Möchten Sie dieses Auskunfts-, Berichtigungs-, Löschungs-, Einschränkungs- oder
                            Widerspruchsrecht in Anspruch nehmen, können Sie sich hierzu jederzeit unter den oben
                            angegebenen Kontaktdaten an uns wenden. Weiterhin stehen wir Ihnen für Fragen zur Erhebung,
                            Verarbeitung und Nutzung Ihrer personenbezogenen Daten zur Verfügung.
                        </p>
                        <h1 className='text-2xl text-gray-800 font-normal mb-1 mt-4'>
                            Dauer der Speicherung, routinemäßige Löschung und Sperrung von personenbezogenen Daten
                        </h1>
                        <p className='text-gray-800 font-normal mb-1 mt-2'>
                            Die von uns verarbeiteten Daten werden nach Maßgabe der Art. 17 und 18 DSGVO gelöscht oder
                            in ihrer Verarbeitung eingeschränkt. Sofern nicht im Rahmen dieser Datenschutzerklärung
                            ausdrücklich angegeben, werden die bei uns gespeicherten Daten gelöscht, sobald sie für ihre
                            Zweckbestimmung nicht mehr erforderlich sind und der Löschung keine gesetzlichen
                            Aufbewahrungspflichten entgegenstehen. Sofern die Daten nicht gelöscht werden, weil sie für
                            andere und gesetzlich zulässige Zwecke erforderlich sind, wird deren Verarbeitung
                            eingeschränkt, d.h. die Daten werden gesperrt und nicht für andere Zwecke verarbeitet. Das
                            gilt z.B. für Daten, die aus handels- oder steuerrechtlichen Gründen aufbewahrt werden
                            müssen.
                        </p>
                        <h1 className='text-2xl text-gray-800 font-normal mb-1 mt-4'>Veröffentlichte Kontaktdaten</h1>
                        <p className='text-gray-800 font-normal mb-1 mt-2'>
                            Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte
                            zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien
                            wird hiermit widersprochen. Wir behalten uns ausdrücklich rechtliche Schritte im Falle der
                            unverlangten Zusendung von Werbeinformationen etwa durch Spam-Emails vor.
                        </p>
                        <h1 className='text-2xl text-gray-800 font-normal mb-1 mt-4'>
                            Änderungen der Datenschutzerklärung
                        </h1>
                        <p className='text-gray-800 font-normal mb-1 mt-2'>
                            Wir behalten uns vor, diese Datenschutzerklärung jederzeit ohne gesonderte Ankündigung zu
                            ändern. Die geltende Datenschutzerklärung kann innerhalb der Webseite eingesehen werden.
                        </p>
                        <p className='text-gray-800 font-medium mb-1 mt-3'>Stand: August 2024</p>
                    </div>
                    <div className=' mt-4'>
                        <div className=' inline rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20'>
                            <Link to='/' className='font-semibold text-[#826E42]'>
                                <span className='' aria-hidden='true'></span>Zurück zur Startseite{' '}
                                <span aria-hidden='true'>&rarr;</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export function Component() {
    return <DataPrivacy />
}
