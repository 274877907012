import { ReactNode } from 'react'
import { motion } from 'framer-motion'

type Props = {
    children: ReactNode
}

// the variants here define the animaion
const variants = {
    hidden: { opacity: 0, x: 0, y: -20 },
    enter: { opacity: 1, x: 0, y: 0 },
    exit: { opacity: 0, x: 0, y: 20 }
}

export const NavTransition = ({ children }: Props): React.JSX.Element => {
    return (
        <motion.div
            initial='hidden'
            animate='enter'
            exit='exit'
            variants={variants}
            transition={{ duration: 0.1, type: 'easeInOut' }}
            className='relative'>
            {children}
        </motion.div>
    )
}
