import { Link, useSearchParams } from 'react-router-dom'
import { JSXElementConstructor, Key, ReactElement, ReactNode, ReactPortal, useEffect, useState } from 'react'
import img_1 from '@/assets/h2.webp'
import './Career2.scss'

export const Career2 = () => {
    const showLink = true
    const [jobs, setJobs] = useState<Array<any> | null>(null)
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [searchParams] = useSearchParams()
    console.log(searchParams.get('force'))
    useEffect(() => {
        fetch('/_jobs' + (searchParams.get('force') === '1' ? '?force=1' : ''), {
            method: 'GET'
        })
            .then(response => response.json())
            .then(data => {
                if (!data.result) {
                    setError(true)
                } else {
                    setJobs(data.data)
                }
                setLoading(false)
            })
            .catch(error => {
                setError(true)
                setLoading(false)
                console.log(error)
            })
    }, [searchParams])

    return (
        <>
            <div className='pt-10 flex-grow flex items-center'>
                <div className='max-w-[85rem]  mx-auto px-4 sm:px-6 lg:px-8'>
                    <div className='text-center'>
                        <h1 className=' font-bold tracking-tight text-gray-900 text-4xl sm:text-5xl lg:text-6xl'>
                            Wir wachsen und suchen Verstärkung!
                            <br />
                            Sind <span className='text-[#826E42]'>Sie</span> bereit{' '}
                            <span className='text-[#826E42]'>für Neues</span>?
                        </h1>
                    </div>
                </div>
            </div>
            <main id='content' className='pt-6 pb-10 flex-grow flex flex-col items-center'>
                <div className='max-w-[85rem]  mx-auto px-4 sm:px-6 lg:px-8'>
                    <div className='grid md:grid-cols-3  gap-4 md:gap-8 xl:gap-20 md:items-center'>
                        <div className=' items-center col-span-2'>
                            <p className='mt-3 text-lg text-gray-800'>
                                Schauen Sie sich unsere aktuellen Stellenangebote an und starten Sie jetzt Ihre Karriere
                                bei der Majo Holding GmbH.
                            </p>{' '}
                            <p className='mt-3 text-lg text-gray-800'>
                                Der Klick auf eine offene Stelle führt Sie auf die Stellenausschreibung von{' '}
                                <a className={'hover:underline'} href={'https://heyrecruit.de'}>
                                    heyrecruit.de
                                </a>
                                . Dort finden Sie die Details zur ausgeschriebenen Stelle und können sich schnell und
                                unkompliziert bewerben.
                            </p>
                            <div className='flex mt-10 justify-center '>
                                {loading ? <div className='loader'></div> : null}
                            </div>
                        </div>

                        <div className='relative ms-4'>
                            <img className='w-full rounded-md' src={img_1} alt='Managing the future.' />
                            <div className='absolute inset-0 -z-[1] bg-gradient-to-tr from-[#826E42] via-white/0 to-white/0 size-full rounded-md mt-4 -mb-4 me-4 -ms-4 lg:mt-6 lg:-mb-6 lg:me-6 lg:-ms-6'></div>
                        </div>
                    </div>
                </div>
                <div className='w-full max-w-[85rem] mt-4  mx-auto px-4 sm:px-6 lg:px-8'>
                    <section id='career' className='w-full  mx-auto grid auto-rows-fr grid-cols-1 mt-10 mb-2'>
                        {error ? (
                            <>
                                <div className='p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-100' role='alert'>
                                    <span className='font-medium'>Entschuldigung!</span>
                                    <br /> Beim Laden der Stellenangebote ist ein Fehler aufgetreten. Bitte versuchen
                                    Sie es zu einem späteren Zeitpunkt erneut. Alternativ können Sie uns gerne auch
                                    direkt per Email kontaktieren:
                                    <div className='flex row mt-2 items-center'>
                                        <div className='flex h-10 w-10 items-center justify-center '>
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                fill='rgb(31 41 55 / var(--tw-text-opacity))'
                                                stroke='rgb(31 41 55 / var(--tw-text-opacity))'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                className='h-6 w-6'
                                                viewBox='30 31.5 452 452'>
                                                <g fillRule='evenodd'>
                                                    <path d='M 441,81 A 40,40 0 0 1 481,121 L 481,395 A 40,40 0 0 1 441,435 L 71,435 A 40,40 0 0 1 31,395 L 31,121 A 40,40 0 0 1 71,81 Z M 63,121 A 8,8 0 0 1 71,113 L 441,113 A 8,8 0 0 1 449,121 L 449,131 C 448,150 438,166 417,188 L 288,291 C258,311 254,311 224,291 L 95,188 C 74,166 64,150 63,131 Z M 63,202 C 66,205 75,214 80,218 L 208,320 C 245,344 265,344 304,320 L 432,218 C 437,214 446,205 449,202 L 449,394 A 8,8 0 0 1 441,402 L 71,402 A 8,8 0 0 1 63,394 Z' />
                                                </g>
                                            </svg>
                                        </div>
                                        <div className='ml-4'>
                                            <a
                                                className='text-gray-800 hover:underline'
                                                href='mailto:info@majo-holding.de'>
                                                info@majo-holding.de
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : jobs ? (
                            <>
                                <div className={''}>
                                    {jobs.map(
                                        (job: {
                                            subtitle: string | null
                                            id: Key | null | undefined
                                            link: string | undefined
                                            title:
                                                | string
                                                | number
                                                | boolean
                                                | ReactElement<any, string | JSXElementConstructor<any>>
                                                | Iterable<ReactNode>
                                                | ReactPortal
                                                | null
                                                | undefined
                                            company:
                                                | string
                                                | number
                                                | boolean
                                                | ReactElement<any, string | JSXElementConstructor<any>>
                                                | Iterable<ReactNode>
                                                | ReactPortal
                                                | null
                                                | undefined
                                            location:
                                                | string
                                                | number
                                                | boolean
                                                | ReactElement<any, string | JSXElementConstructor<any>>
                                                | Iterable<ReactNode>
                                                | ReactPortal
                                                | null
                                                | undefined
                                            type:
                                                | string
                                                | number
                                                | boolean
                                                | ReactElement<any, string | JSXElementConstructor<any>>
                                                | Iterable<ReactNode>
                                                | ReactPortal
                                                | null
                                                | undefined
                                        }) => {
                                            return (
                                                <div key={job.id} className='job-tile sm:flex mb-3.5'>
                                                    <div className='job-info-wrap'>
                                                        <a target='_blank' href={job.link}>
                                                            <h2 className='text-[#826E42] hover:text-[#aa9158] job-h2'>
                                                                {job.title}
                                                            </h2>
                                                        </a>
                                                        <div className='lg:flex flex-row gap-4'>
                                                            <span className='job-inner-element'>
                                                                <svg
                                                                    viewBox='0 0 500 500'
                                                                    width='24'
                                                                    height='20'
                                                                    fill='#000'
                                                                    stroke='#000'
                                                                    strokeWidth='0'
                                                                    strokeLinecap='round'
                                                                    strokeLinejoin='round'
                                                                    className='inline-block mr-1'>
                                                                    <path d='M225,62a38,38 0 01 50,0l73,61v-59.6a10,10 0 01 10-10h59a10,10 0 01 10,10v125.6l68,56.5a10,10 0 01 0,13l-19.8,23.5a10,10 0 01-13,0l-212.2-177l-212.2,177a10,10 0 01-13,0l-19.8-23.5a10,10 0 01 0-13z' />
                                                                    <path d='M250,132l177,146v149a20,20 0 01-20,20h-118v-118h-78v118h-118a20,20 0 01-20-20v-149z' />
                                                                </svg>
                                                                {job.company}
                                                            </span>
                                                            <div className='job-inner-element'>
                                                                <svg
                                                                    xmlns='http://www.w3.org/2000/svg'
                                                                    width='24'
                                                                    height='24'
                                                                    viewBox='0 0 24 24'
                                                                    fill='none'
                                                                    stroke='currentColor'
                                                                    strokeWidth='2'
                                                                    strokeLinecap='round'
                                                                    strokeLinejoin='round'
                                                                    className='inline-block mr-1'>
                                                                    <path d='M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0'></path>
                                                                    <path d='M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z'></path>
                                                                </svg>
                                                                {job.location}
                                                            </div>
                                                            <span className='job-inner-element'>
                                                                <svg
                                                                    viewBox='0 0 500 500'
                                                                    width='24'
                                                                    height='20'
                                                                    fill='#000'
                                                                    stroke='#000'
                                                                    strokeWidth='0'
                                                                    strokeLinecap='round'
                                                                    strokeLinejoin='round'
                                                                    className='inline-block mr-1'>
                                                                    <g fillRule='evenodd'>
                                                                        <path d='M250,5a245,245 0 01 0,490a245,245 0 01 0-490zm0,70a175,175 0 01 0,350a175,175 0 01 0-350z' />
                                                                        <path d='M250,250v-112a10,10 0 01 10-10h21a10,10 0 01 10,10v143a10,10 0 01-10,10h-102a10,10 0 01-10-10v-21a10,10 0 01 10-10z' />
                                                                    </g>
                                                                </svg>
                                                                {job.type}
                                                            </span>
                                                            <span className='job-inner-element'></span>
                                                        </div>
                                                    </div>

                                                    <div className='relative inline-block lg:block mt-4 lg:mt-0 rounded-full px-3 py-1 text-sm leading-6 text-white bg-[#826E42] hover:bg-[#aa9158] min-w-[167px]'>
                                                        <a href={job.link} className='font-semibold '>
                                                            <span
                                                                className='absolute inset-0'
                                                                aria-hidden='true'></span>
                                                            Zur Stellenanzeige <span aria-hidden='true'>&rarr;</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    )}
                                </div>
                            </>
                        ) : null}
                    </section>
                    <div className='max-w-[85rem]  mx-auto px-4 sm:px-6 lg:px-8'>
                        <div className='relative isolate px-6 pt-14 lg:px-8'>
                            <div className='mx-auto max-w-2xl'>
                                {showLink ? (
                                    <div className='flex sm:mb-8 sm:flex sm:justify-center'>
                                        <div className='relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20'>
                                            <Link to='/' className='font-semibold text-[#826E42]'>
                                                <span className='absolute inset-0' aria-hidden='true'></span>Zurück zur
                                                Startseite <span aria-hidden='true'>&rarr;</span>
                                            </Link>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export function Component() {
    return <Career2 />
}
