import { Link } from 'react-router-dom'

export const Error404 = () => {
    return (
        <>
            <main id='content' className='py-10 flex-grow flex items-center'>
                <div className='max-w-[85rem]  mx-auto px-4 sm:px-6 lg:px-8'>
                    Error404
                    <br /> <Link to='/'>Back</Link>
                </div>
            </main>
        </>
    )
}

export function Component() {
    return <Error404 />
}
